'use client'

import { useCallback, useEffect, useState } from 'react'

export type StepType = {
  name: string
  id: number
  label: string
  description?: string
  isCompleted: boolean
}

export type UseStepsReturnType = {
  steps: StepType[]
  currentStep: StepType
  goToNextStep: () => Promise<void> | void
  goToPreviousStep: () => Promise<void> | void
  goToStep: (name: string) => Promise<void> | void
  setSteps: (newSteps: StepType[]) => void
  completeStep: (name: string, isCompleted: boolean) => void
}

export const useSteps = (initialSteps: StepType[]): UseStepsReturnType => {
  const [steps, setSteps] = useState<StepType[]>(initialSteps)
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)

  useEffect(() => {
    setSteps(initialSteps)
  }, [initialSteps])

  const goToNextStep = useCallback(() => {
    setCurrentStepIndex((prevIndex) =>
      prevIndex < steps.length - 1 ? prevIndex + 1 : prevIndex
    )
  }, [steps.length])

  const goToPreviousStep = useCallback(() => {
    setCurrentStepIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    )
  }, [])

  const goToStep = useCallback(
    (name: string) => {
      const stepIndex = steps.findIndex((step) => step.name === name)
      if (stepIndex !== -1) {
        setCurrentStepIndex(stepIndex)
      }
    },
    [steps]
  )

  const completeStep = useCallback(
    (name: string, isCompleted: boolean) => {
      const stepIndex = steps.findIndex((step) => step.name === name)
      if (stepIndex !== -1) {
        setSteps((prevSteps) =>
          prevSteps.map((step, index) =>
            index === stepIndex ? { ...step, isCompleted: isCompleted } : step
          )
        )
      }
    },
    [steps]
  )

  return {
    steps,
    currentStep: steps[currentStepIndex],
    goToNextStep,
    goToPreviousStep,
    goToStep,
    setSteps,
    completeStep
  }
}
